import HttpClient from './http.api';

export class ClientApiService {

    static fetchAllClients(data) {
        return HttpClient.post({ url: 'api/v1/web/client/list', data });
    }

    static fetchAllClientsInJobs(data) {
        return HttpClient.post({ url: 'api/v1/web/client/listInJob', data });
    }

    static createClient(data) {
        return HttpClient.post({ url: 'api/v1/web/client/add', data });
    }

    static getClientById(id) {
        return HttpClient.get({ url: 'api/v1/web/client/' + id });
    }

    static getLeadAsClientById(id) {
        return HttpClient.get({ url: 'api/v1/web/client/lead/' + id });
    }

    static updateClientById(id, data) {
        return HttpClient.put({ url: 'api/v1/web/client/update/' + id, data });
    }

    static deleteClientById(data) {
        return HttpClient.post({ url: 'api/v1/web/client/delete', data });
    }

}
